import { AddressRequest } from "./AddressModel";
import { IPartnerProduct } from "./PartnerProductModel";

export class ChosenProducts {
	electricityProduct: IPartnerProduct | undefined;
	chargingstandProduct: IPartnerProduct | undefined;
}

export class OrderModel {
	fullName: string = "";
	cohabFullName: string = "";
	deliveryAddress: string = "";
	deliveryAddressId: string = "";
	billingAddress: string = "";
	billingAddressId: string = "";
	cpr: string = "";
	cohabCpr: string = "";
	email: string = "";
	phone: string = "";
	acceptedTerms: boolean = false;
	newsletterSignup: boolean = false;
	chosenProducts: ChosenProducts = new ChosenProducts();
	startDate?: Date = undefined;
	IDQValid: boolean = false;
	DatahubValid: boolean = false;
	CohabIDQValid: boolean = false;
	CohabDatahubValid: boolean = false;
	InDelivery: boolean = false;
	InBinding: boolean = false;
	subscriptionProduct?: number = 0;
	salesPersonId: string = "";
	shopId: string = ""; //Butiksnummer
	emobilityWishedInstallationMonth: Date | undefined;
	emobilityWishedInstallationMonthName: string = "";
	emobilityRemovalOfChargingStand: boolean | undefined = false;
	emobilitySelected: boolean = false;
	emobilityDealerFunded: boolean = false;
	emobilityCampaign: string = "";
	addressRequest: AddressRequest = new AddressRequest();
	digitalLead: boolean = false;
	existingCustomer: boolean = false;
	separateDeliveryAddress: boolean = false;
	separateDeliveryAddressId: string | undefined;
	separateDeliveryAddressRequest: AddressRequest | undefined;
	separateDeliveryAddressString: string = "";
	shopInstallationNumber: number | undefined = undefined;
	shopName: string = "";
	isSalesFlow: boolean = false;
	urlParameter: string = "";

	constructor(
		fullName: string = "",
		deliveryAddress: string = "",
		email: string = "",
		phone: string = "",
		cpr: string = "",
		subscriptionProduct?: number,
		salesPersonId: string = "",
		shopId: string = ""
	) {
		this.fullName = fullName;
		this.deliveryAddress = deliveryAddress;
		this.cpr = cpr;
		this.email = email;
		this.phone = phone;
		this.subscriptionProduct = subscriptionProduct;
		this.salesPersonId = salesPersonId;
		this.shopId = shopId;
	}
}
