import { AddressRequest } from "./AddressModel";
import { OrderModel } from "./OrderModel";

class CustomerRequest {
	Phone: string | undefined;
	Email: string | undefined;
	Cpr: string | undefined;
	FullName: string | undefined;
}

class CohabitantRequest {
	Cpr: string | undefined;
	FullName: string | undefined;
}

class SalesRequest {
	SalesPersonId: string | undefined;
	ShopId: string | undefined;
	ShopName: string | undefined;
}

class EmobilityRequest {
	WishedInstallationMonth: Date | undefined;
	RemovalOfChargingStand: boolean | undefined;
	DealerFunded: boolean | undefined;
	Campaign: string | undefined;
	SeparateDeliveryAddress: boolean = false;
	SeparateDeliveryAddressId: string | undefined;
	SeparateDeliveryAddressRequest: AddressRequest | undefined;
}

class ProductIds {
	electricityProductId: string | undefined;
	chargingstandProductId: string | undefined;
}

export class OrderRequest {
	Customer: CustomerRequest | undefined;
	Cohabitant: CohabitantRequest | undefined;
	ChosenProductIds: ProductIds = new ProductIds();
	EstimatedYearlyUsage?: number | undefined | null;
	OrderOrigin: string | undefined;
	StartDate?: Date | null;
	ElectricHeat: boolean | undefined;
	NewsletterSignup: boolean | undefined;
	DeliveryAddressId: string | undefined;
	BillingAddressId: string | undefined;
	Resale: boolean | undefined;
	SalesInformation: SalesRequest | undefined;
	EmobilityRequest: EmobilityRequest | undefined;
	EmobilitySelected: boolean = false;
	AddressRequest: AddressRequest = new AddressRequest();
	DigitalLead: boolean = false;
	ExistingCustomer: boolean = false;
	ProductId: string = "";
	PartnerInstallationNumber: string = "";
	isSalesFlow: boolean = false;
	urlParameter: string = "";
}

export const makeOrder = (
	order: OrderModel,
	orderOrigin: string,
	partnerInstallationNumber: string,
	yearlyUsage: number
): OrderRequest => {
	let dto = new OrderRequest();

	let cu = new CustomerRequest();
	cu.Cpr = order.cpr;
	cu.Email = order.email;
	cu.FullName = order.fullName;
	cu.Phone = order.phone;
	dto.Customer = cu;
	dto.AddressRequest = order.addressRequest;

	if (order.cohabCpr && order.cohabFullName) {
		let co = new CohabitantRequest();
		co.Cpr = order.cohabCpr;
		co.FullName = order.cohabFullName;
		dto.Cohabitant = co;
	}

	dto.DeliveryAddressId = order.deliveryAddressId;
	dto.BillingAddressId = order.billingAddressId ? order.billingAddressId : order.deliveryAddressId;
	dto.ElectricHeat = false;
	dto.EstimatedYearlyUsage = yearlyUsage;
	dto.OrderOrigin = orderOrigin;
	if (order.startDate) {
		dto.StartDate = order.startDate;
	}
	dto.NewsletterSignup = order.newsletterSignup;
	dto.ChosenProductIds.chargingstandProductId =
		order.chosenProducts.chargingstandProduct?.id ?? undefined;
	dto.ChosenProductIds.electricityProductId =
		order.chosenProducts.electricityProduct?.id ?? undefined;
	dto.Resale = !order.startDate && order.InDelivery;

	if (order.salesPersonId && order.shopId) {
		let sr = new SalesRequest();
		sr.SalesPersonId = order.salesPersonId;
		sr.ShopId = order.shopId;
		sr.ShopName = order.shopName;
		dto.SalesInformation = sr;
	}

	if (order.emobilitySelected) {
		let emob = new EmobilityRequest();
		emob.RemovalOfChargingStand = order.emobilityRemovalOfChargingStand;
		emob.WishedInstallationMonth = order.emobilityWishedInstallationMonth;
		emob.DealerFunded = order.emobilityDealerFunded;
		emob.Campaign = order.emobilityCampaign;
		dto.EmobilitySelected = true;
		dto.EmobilityRequest = emob;
		emob.SeparateDeliveryAddress = order.separateDeliveryAddress;
		emob.SeparateDeliveryAddressId = order.separateDeliveryAddressId;
		emob.SeparateDeliveryAddressRequest = order.separateDeliveryAddressRequest;
	}

	dto.DigitalLead = order.digitalLead;
	dto.ExistingCustomer = order.existingCustomer;
	dto.PartnerInstallationNumber = order.shopInstallationNumber
		? order.shopInstallationNumber.toString()
		: "";
	dto.isSalesFlow = order.isSalesFlow;
	dto.urlParameter = order.urlParameter;
	return dto;
};
